import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const Users = React.lazy(() => import('../../views/Users'));

const params = [];
const UsersRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <Users />
      </Layout>
    </RouteParameter>
  );
};

UsersRoute.propTypes = {};

UsersRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(UsersRoute);

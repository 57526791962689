import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../config/routes';
import DashboardRoute from './Dashboard';
import ManualCaseRoute from './ManualCase';
import AddManualCaseRoute from './AddManualCase';
import CategoriesRoute from './Categories';
import AddCategoryRoute from './AddCategory';
import ConsultationHistoryRoute from './ConsultationHistory';
import HospitalRoute from './Hospital';
import AddGroupRoute from './AddGroup';
import AddHospitalRoute from './AddHospital';
import AddClinicRoute from './AddClinic';
import DoctorsRoute from './Doctors';
import AddDoctorRoute from './AddDoctor';
import AddDoctorSignatureRoute from './AddDoctorSignature';
import DoctorPriceAndSpecsRoute from './UpdateDoctorPrice';
import PrescriptionsRoute from './Prescriptions';
import AddPrescriptionRoute from './AddPrescription';
import ScriptsPlansRoute from './ScriptsPlans';
import AddCouponRoute from './AddCoupon';
import AddPartnerRoute from './AddPartner';
import AddPlanRoute from './AddPlan';
import AddICDRoute from './AddICD';
import ProfileRoute from './Profile';
import UsersRoute from './Users';
import UserProfileRoute from './UserProfile';
import AddUserRoute from './AddUser';
import UsersAndPermissionRoute from './UsersAndPermission';
import AddPersonnelRoute from './AddPersonnel';
import SettingsPageRoute from './SettingsPage';
import AddRoutePage from './AddRoute';
import AddCompaignRoute from './AddCompaign';
import AddShortURLRoute from './AddShortURL';
import AddDoctorTeamRoute from './AddDoctorTeam';
import AddWebhookRoute from './AddWebhook/index';

import Fallback from './Fallback';

const Routing = () => (
  <Routes>
    <Route path={routes.dashboard} element={<DashboardRoute />} />
    <Route path={routes.manualCases} element={<ManualCaseRoute />} />
    <Route path={routes.addManualCase} element={<AddManualCaseRoute />} />
    <Route path={routes.categories} element={<CategoriesRoute />} />
    <Route path={routes.addCategory} element={<AddCategoryRoute />} />
    <Route
      path={routes.consultationHistory}
      element={<ConsultationHistoryRoute />}
    />
    <Route path={routes.hospital} element={<HospitalRoute />} />
    <Route path={routes.addGroup} element={<AddGroupRoute />} />
    <Route path={routes.addHospital} element={<AddHospitalRoute />} />
    <Route path={routes.addClinic} element={<AddClinicRoute />} />
    <Route path={routes.doctors} element={<DoctorsRoute />} />
    <Route path={routes.addDoctor} element={<AddDoctorRoute />} />
    <Route
      path={routes.addDoctorSignature}
      element={<AddDoctorSignatureRoute />}
    />
    <Route
      path={routes.addDoctorPriceAndSpecs}
      element={<DoctorPriceAndSpecsRoute />}
    />
    <Route path={routes.prescriptions} element={<PrescriptionsRoute />} />
    <Route path={routes.addPrescription} element={<AddPrescriptionRoute />} />
    <Route path={routes.scriptsPlans} element={<ScriptsPlansRoute />} />
    <Route path={routes.addCoupon} element={<AddCouponRoute />} />
    <Route path={routes.addPartner} element={<AddPartnerRoute />} />
    <Route path={routes.addPlan} element={<AddPlanRoute />} />
    <Route path={routes.addICD} element={<AddICDRoute />} />
    <Route path={routes.profile} element={<ProfileRoute />} />
    <Route path={routes.users} element={<UsersRoute />} />
    <Route path={routes.userProfile} element={<UserProfileRoute />} />
    <Route path={routes.addUser} element={<AddUserRoute />} />
    <Route
      path={routes.usersAndPermission}
      element={<UsersAndPermissionRoute />}
    />
    <Route path={routes.addPersonnel} element={<AddPersonnelRoute />} />
    <Route path={routes.settings} element={<SettingsPageRoute />} />
    <Route path={routes.addRoute} element={<AddRoutePage />} />
    <Route path={routes.addCompaign} element={<AddCompaignRoute />} />
    <Route path={routes.addShortURL} element={<AddShortURLRoute />} />
    <Route path={routes.addDoctorTeam} element={<AddDoctorTeamRoute />} />
    <Route path={routes.addWebhook} element={<AddWebhookRoute />} />
    <Route path="*" element={<Fallback />} />
  </Routes>
);

export default Routing;

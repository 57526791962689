import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const AddCoupon = React.lazy(() => import('../../views/AddCoupon'));

const params = [];

const AddCouponRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <AddCoupon />
      </Layout>
    </RouteParameter>
  );
};

AddCouponRoute.propTypes = {};

AddCouponRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AddCouponRoute);

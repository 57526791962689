import { createStore, applyMiddleware, compose } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as redux from '@doctoroncallcom/redux';
import reducers from './reducers';
import api from '../utils/api';
import { persist } from '../redux';

const persistConfig = {
  key: process.env.REACT_APP_APP_NAME,
  version: 1,
  storage,
  migrate: (state) => Promise.resolve(state),
  whitelist: [persist.rootReducerKey],
};

const rootReducer = (state, action) => {
  const modifiedState = state;
  return reducers(modifiedState, action);
};

export const rootEpic = combineEpics(
  redux.user.epics.getLoggedInUser,
  redux.user.epics.postUser,
  redux.user.epics.importUser,
  redux.user.epics.getAllUsersList,
  redux.user.epics.getSingleUser,
  redux.user.epics.updateUser,
  redux.user.epics.deleteUser,
  redux.user.epics.getAdminDoctorsList,
  redux.user.epics.getAdminDoctor,
  redux.user.epics.createAdminDoctor,
  redux.user.epics.updateAdminDoctor,
  redux.user.epics.updateAdminDoctorStatus,
  redux.user.epics.postAdminDoctorDigitalSig,
  redux.user.epics.revokeAdminDoctorDigitalSig,
  redux.user.epics.postAdminDoctorAuthPin,
  redux.user.epics.postAdminDoctorSpecs,
  redux.user.epics.deleteAdminDoctor,
  redux.user.epics.getadminPanelUsersList,
  redux.user.epics.getSingleAdminPanelUser,
  redux.user.epics.createAdminPanelUser,
  redux.user.epics.updateAdminPanelUser,
  redux.user.epics.profile,
  redux.user.epics.updateProfile,
  redux.user.epics.logoutUser,
  redux.consultations.epics.getAdminConsultationsList,
  redux.consultations.epics.sendAdminConsultationsCSVEmail,
  redux.consultations.epics.getAdminConsultation,
  redux.consultations.epics.createAdminConsultation,
  redux.consultations.epics.updateAdminConsultation,
  redux.consultations.epics.deleteAdminConsultation,
  redux.consultations.epics.updateStatusAdminConsultation,
  redux.fileDomain.epics.getFileAdminEpic,
  redux.fileDomain.epics.getFileAndAppendAdminEpic,
  redux.fileDomain.epics.uploadFilesAdminEpic,
  redux.fileDomain.epics.getUploadFilesUrlAdminEpic,
  redux.hospital.epics.getHospitalsList,
  redux.hospital.epics.getHospital,
  redux.hospital.epics.createHospital,
  redux.hospital.epics.updateHospital,
  redux.hospital.epics.updateHospitalStatus,
  redux.hospital.epics.deleteHospital,
  redux.group.epics.getGroupList,
  redux.group.epics.getGroup,
  redux.group.epics.createGroup,
  redux.group.epics.updateGroup,
  redux.group.epics.deleteGroup,
  redux.partner.epics.getPartnerList,
  redux.partner.epics.getPartner,
  redux.partner.epics.createPartner,
  redux.partner.epics.updatePartner,
  redux.partner.epics.deletePartner,
  redux.icd.epics.getIcdList,
  redux.icd.epics.getIcd,
  redux.icd.epics.createIcd,
  redux.icd.epics.updateIcd,
  redux.icd.epics.deleteIcd,
  redux.specification.epics.getSpecificationsList,
  redux.specification.epics.getSpecification,
  redux.specification.epics.createSpecification,
  redux.specification.epics.updateSpecification,
  redux.specification.epics.deleteSpecification,
  redux.couponDomain.epics.getCouponList,
  redux.couponDomain.epics.getCoupon,
  redux.couponDomain.epics.createCoupon,
  redux.couponDomain.epics.updateCoupon,
  redux.couponDomain.epics.deleteCoupon,
  redux.plan.epics.getPlanList,
  redux.plan.epics.getPlan,
  redux.plan.epics.createPlan,
  redux.plan.epics.updatePlan,
  redux.plan.epics.deletePlan,
  redux.setting.epics.getCampaignList,
  redux.setting.epics.createCampaign,
  redux.setting.epics.updateCampaign,
  redux.setting.epics.deleteCampaign,
  redux.setting.epics.getRouteList,
  redux.setting.epics.createRoute,
  redux.setting.epics.updateRoute,
  redux.setting.epics.deleteRoute,
  redux.setting.epics.doTestAudioCall,
  redux.setting.epics.getPriorityTeamList,
  redux.setting.epics.geWebhookList,
  redux.setting.epics.getWebhook,
  redux.setting.epics.createWebhook,
  redux.setting.epics.deleteWebhook,
  redux.setting.epics.getPriorityTeamDetail,
  redux.setting.epics.createPriorityTeam,
  redux.setting.epics.updatePriorityTeam,
  redux.setting.epics.addDoctorToPriorityTeam,
  redux.setting.epics.deletePriorityTeam,
  redux.shorturl.epics.getShorturlList,
  redux.shorturl.epics.getShorturl,
  redux.shorturl.epics.createShorturl,
  redux.shorturl.epics.updateShorturl,
  redux.shorturl.epics.deleteShorturl,
  redux.prescriptions.epics.getPrescriptionList,
  redux.prescriptions.epics.getPrescription,
  redux.prescriptions.epics.createPrescription,
  redux.prescriptions.epics.updatePrescription,
  redux.prescriptions.epics.deletePrescription,
  redux.prescriptions.epics.updatePrescriptionStatus,
  redux.prescriptions.epics.addPrescriptionValidity,
  redux.trustgate.epics.getCertificateInfo
);
const epicMiddleware = createEpicMiddleware({
  dependencies: { api },
});

export default function configureStore(initialState = {}) {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const middlewares = [epicMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  epicMiddleware.run(rootEpic);

  const persistor = persistStore(store);

  // Extensions
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.persistor = persistor;

  return { store, persistor };
}

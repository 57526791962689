import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const AddShortURL = React.lazy(() => import('../../views/AddShortURL'));

const params = [];

const AddShortURLRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <AddShortURL />
      </Layout>
    </RouteParameter>
  );
};

AddShortURLRoute.propTypes = {};

AddShortURLRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AddShortURLRoute);

import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const DoctorPriceAndSpecs = React.lazy(() =>
  import('../../views/DoctorPriceAndSpecs')
);

const params = [];

const DoctorPriceAndSpecsRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <DoctorPriceAndSpecs />
      </Layout>
    </RouteParameter>
  );
};

DoctorPriceAndSpecsRoute.propTypes = {};

DoctorPriceAndSpecsRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DoctorPriceAndSpecsRoute);

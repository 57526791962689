import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const AddPersonnel = React.lazy(() => import('../../views/AddPersonnel'));

const params = [];

const AddPersonnelRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <AddPersonnel />
      </Layout>
    </RouteParameter>
  );
};

AddPersonnelRoute.propTypes = {};

AddPersonnelRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AddPersonnelRoute);

import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ToggleMenuContextProvider } from './toggleMenuContext';
import FullPageLoader from '../../components/Loaders/FullPageLoader';

import Header from '../Header';
import './style.css';

const Layout = ({ children }) => {
  const { height } = useWindowDimensions();
  const [showHeader, setShowHeader] = useState(false);
  const toggleMenu = (e) => {
    e.preventDefault();
    setShowHeader(!showHeader);
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      setShowHeader(true);
    }
  }, []);
  return (
    <div className="w-full flex" style={{ height }}>
      {window.location.pathname !== '/network-error' &&
      window.location.pathname !== '/patient/consultation' ? (
        <Header toggleMenu={toggleMenu} showHeader={showHeader} />
      ) : null}
      <Suspense fallback={<FullPageLoader />}>
        <div
          className={`h-screen overflow-auto w-full z-0 ${
            window.location.pathname !== '/network-error' &&
            window.location.pathname !== '/patient/consultation'
              ? 'content-con'
              : ''
          }`}
        >
          <ToggleMenuContextProvider
            value={{
              toggleMenu,
            }}
          >
            {children}
          </ToggleMenuContextProvider>
        </div>
      </Suspense>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

Layout.defaultProps = {
  children: undefined,
};
export default Layout;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const AddCategory = React.lazy(() => import('../../views/AddCategory'));

const params = [];

const AddCategoryRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <AddCategory />
      </Layout>
    </RouteParameter>
  );
};

AddCategoryRoute.propTypes = {};

AddCategoryRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AddCategoryRoute);

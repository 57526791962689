const routes = {
  dashboard: '/',
  manualCases: '/manual-cases',
  addManualCase: '/manual-case/add',
  categories: '/categories',
  addCategory: '/categories/add',
  consultationHistory: '/consultation/history',
  hospital: '/hospital',
  addGroup: '/hospital/add-group',
  addHospital: '/hospital/add',
  addClinic: '/hospital/add-clinic',
  doctors: '/doctors',
  addDoctor: '/doctor/add',
  addDoctorSignature: '/doctor/signature/add',
  addDoctorPriceAndSpecs: '/doctor/price-and-specs/add',
  prescriptions: '/prescriptions',
  addPrescription: '/prescription/add',
  scriptsPlans: '/scripts-plans',
  addCoupon: '/add-coupon',
  addPartner: '/add-partner',
  addPlan: '/add-plan',
  addICD: '/add-icd',
  profile: '/profile',
  users: '/users',
  userProfile: '/user/profile',
  addUser: '/user/add',
  usersAndPermission: '/users-permission',
  addPersonnel: '/users-permission/add',
  settings: '/settings',
  addRoute: '/settings/route/add',
  addCompaign: '/settings/campaign/add',
  addShortURL: '/settings/short-url/add',
  addDoctorTeam: '/settings/doctor-team/add',
  addWebhook: '/settings/webhook/add',
};
export default routes;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const UsersAndPermission = React.lazy(() =>
  import('../../views/UsersAndPermission')
);

const params = [];
const UsersAndPermissionRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <UsersAndPermission />
      </Layout>
    </RouteParameter>
  );
};

UsersAndPermissionRoute.propTypes = {};

UsersAndPermissionRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(UsersAndPermissionRoute);

import { API } from '@doctoroncallcom/api';
import * as session from './session';
// import { API_KEY } from '../config/constants';

const env = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev';

const api = new API(env, { timeout: 30000 });
// .setAPIKey(API_KEY)
// .setCamelCaseTransformer(false);
api.setCamelCaseTransformer(false);
const user = session.getSession();
if (user) {
  api.setAuthorizationToken(user?.persistDomain?.token || '');
}
export default api;

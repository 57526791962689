import { combineReducers } from 'redux';
import {
  user,
  consultations,
  fileDomain,
  hospital,
  group,
  partner,
  icd,
  specification,
  couponDomain,
  plan,
  setting,
  prescriptions,
  shorturl,
  trustgate,
} from '@doctoroncallcom/redux';
import { session, persist } from '../redux/index';

const injectedReducers = {};

export default combineReducers({
  [session.rootReducerKey]: session.reducer,
  [user.rootReducerKey]: user.reducer,
  [consultations.rootReducerKey]: consultations.reducer,
  [fileDomain.rootReducerKey]: fileDomain.reducer,
  [hospital.rootReducerKey]: hospital.reducer,
  [group.rootReducerKey]: group.reducer,
  [partner.rootReducerKey]: partner.reducer,
  [icd.rootReducerKey]: icd.reducer,
  [specification.rootReducerKey]: specification.reducer,
  [couponDomain.rootReducerKey]: couponDomain.reducer,
  [plan.rootReducerKey]: plan.reducer,
  [setting.rootReducerKey]: setting.reducer,
  [prescriptions.rootReducerKey]: prescriptions.reducer,
  [persist.rootReducerKey]: persist.reducer,
  [shorturl.rootReducerKey]: shorturl.reducer,
  [trustgate.rootReducerKey]: trustgate.reducer,
  ...injectedReducers,
});

import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const AddPartner = React.lazy(() => import('../../views/AddPartner'));

const params = [];

const AddPartnerRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <AddPartner />
      </Layout>
    </RouteParameter>
  );
};

AddPartnerRoute.propTypes = {};

AddPartnerRoute.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AddPartnerRoute);

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// eslint-disable-next-line no-unused-vars
import App from './features/App';

import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line import/no-unresolved,import/no-extraneous-dependencies
import 'tailwindcss/dist/tailwind.css';
import './index.css';

if (process.env.REACT_APP_ENV !== 'local')
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_IO,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
  });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

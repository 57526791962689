import redux from '@doctoroncallcom/redux';
import { createSelector } from 'reselect';

const service = 'userSession';
const rootReducerKey = 'userSessionDomain';
const initialState = {
  shoppingDiningOpportunity: true,
  centerUserLocation: true,
  showRoute: true,
};

const constants = {
  SET_SHOPPING_DINING: redux.helpers.createConstant(
    service,
    'SET_SHOPPING_DINING',
    process.env.REACT_APP_APP_NAME
  ),
  RESET_SESSION: redux.helpers.createConstant(
    service,
    'RESET_SESSION',
    process.env.REACT_APP_APP_NAME
  ),
  SET_CENTER_USER_LOCATION: redux.helpers.createConstant(
    service,
    'SET_CENTER_USER_LOCATION',
    process.env.REACT_APP_APP_NAME
  ),
  SET_SHOW_ROUTE: redux.helpers.createConstant(
    service,
    'SET_SHOW_ROUTE',
    process.env.REACT_APP_APP_NAME
  ),
};
const actions = {
  setShoppingDining: (show) =>
    redux.helpers.createAction(constants.SET_SHOPPING_DINING, { show }),
  setCenterUserLocation: (show) =>
    redux.helpers.createAction(constants.SET_CENTER_USER_LOCATION, { show }),
  resetSession: () => redux.helpers.createAction(constants.RESET_SESSION),
  setShowRoute: (show) =>
    redux.helpers.createAction(constants.SET_SHOW_ROUTE, { show }),
};
const reducer = redux.helpers.createReducer(
  {
    [constants.SET_SHOPPING_DINING]: (draft, action) => ({
      ...draft,
      shoppingDiningOpportunity: action.payload.show,
    }),
    [constants.SET_CENTER_USER_LOCATION]: (draft, action) => ({
      ...draft,
      centerUserLocation: action.payload.show,
    }),
    [constants.SET_SHOW_ROUTE]: (draft, action) => ({
      ...draft,
      showRoute: action.payload.show,
    }),
    [constants.RESET_SESSION]: (draft) => ({
      ...draft,
      ...initialState,
    }),
  },
  initialState
);

const selectLocation = (state) => state[rootReducerKey];
const selectors = {
  shoppingDiningOpportunity: () =>
    createSelector(
      [selectLocation],
      (state) => state.shoppingDiningOpportunity
    ),
  showRoute: () => createSelector([selectLocation], (state) => state.showRoute),
  centerUserLocation: () =>
    createSelector([selectLocation], (state) => state.centerUserLocation),
};

export { actions, reducer, selectors, rootReducerKey, constants };

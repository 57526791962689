import redux from '@doctoroncallcom/redux';
import { createSelector } from 'reselect';

const service = 'persist';
const rootReducerKey = 'persistDomain';
const initialState = {
  token: undefined,
};

const constants = {
  SET_TOKEN: redux.helpers.createConstant(
    service,
    'SET_TOKEN',
    process.env.REACT_APP_APP_NAME
  ),
};
const actions = {
  setToken: (token) =>
    redux.helpers.createAction(constants.SET_TOKEN, { token }),
};
const reducer = redux.helpers.createReducer(
  {
    [constants.SET_TOKEN]: (draft, action) => ({
      ...draft,
      token: action.payload.token,
    }),
  },
  initialState
);

const selectState = (state) => state[rootReducerKey];
const selectors = {
  token: () => createSelector([selectState], (state) => state?.token),
};

export { actions, reducer, selectors, rootReducerKey, constants };
